
.float{
	position:fixed;
    z-index: 10;
	bottom:40px;
	right:40px;
}

@media only screen and (max-width: 767px) {
	.mobile-reverse {
		flex-direction: column-reverse;
	}
}
